.embla {
    max-width: 48rem;
    margin: auto;
    --slide-height: 19rem;
    --slide-spacing: 1rem;
    --slide-size: 100%;
  }
  .embla__viewport {
    overflow: hidden;
  }
  .embla__container {
    display: flex;
    touch-action: pan-y pinch-zoom;
    /* margin-left: calc(var(--slide-spacing) * -1); */
  }
  .embla__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
  }
  .embla__slide__number {
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    border-radius: 1.8rem;
    font-size: 4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--slide-height);
    user-select: none;
  }
  .embla-thumbs {
    --thumbs-slide-spacing: 0.8rem;
    --thumbs-slide-height: 6rem;
    margin-top: var(--thumbs-slide-spacing);
  }
  .embla-thumbs__viewport {
    overflow: hidden;
  }
  .embla-thumbs__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap:10px
  }
  .embla-thumbs__slide {
    flex: 0 0 22%;
    min-width: 0;
  }
  @media (min-width: 576px) {
    .embla-thumbs__slide {
      flex: 0 0 15%;
      overflow:hidden;
      border-radius: 10px;
    }
  }
  .embla-thumbs__slide__number {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--detail-high-contrast);
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--thumbs-slide-height);
    width: 100%;
  }
  .embla-thumbs__slide--selected .embla-thumbs__slide__number {
    color: var(--text-body);
  }
  