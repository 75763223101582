.parent .embla2 {
  --slide-height: 13rem;
  --slide-height-small: 10rem;
  --slide-spacing: 1rem;
  --slide-size: 20%;
  --slide-size-small: 15%
}
.parent .embla2__viewport {
  overflow: hidden;
}
.parent .embla2__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.parent .embla2__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  padding-left: var(--slide-spacing);
}
.parent .embla2__slide_small {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size-small);
  padding-left: var(--slide-spacing);
}
.parent .embla2__slide__number {
  /* font-size: 4rem; */
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
  user-select: none;
}
.parent .embla2__slide__number_small {
  /* font-size: 4rem; */
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height-small);
  user-select: none;
}
.parent .embla2__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;
}
.parent .embla2__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
}
.parent .embla2__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.parent .embla2__button:disabled {
  color: var(--detail-high-contrast);
}
.parent .embla2__button__svg {
  width: 35%;
  height: 35%;
}
.parent .embla2__play {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
  color: var(--text-body);
  font-weight: 700;
  /* font-size: 1.4rem; */
  padding: 0 2.4rem;
  min-width: 8.4rem;
}
.parent .embla2__progress {
  border-radius: 1.8rem;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  background-color: var(--background-site);
  position: relative;
  height: 0.6rem;
  justify-self: flex-end;
  align-self: center;
  width: 13rem;
  max-width: 90%;
  overflow: hidden;
}
.parent .embla2__progress__bar {
  background-color: var(--text-body);
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: -100%;
}
.parent .embla2__controls {
  grid-template-columns: auto 1fr auto;
}
.parent .embla2__progress {
  justify-self: center;
  transition: opacity 0.3s ease-in-out;
  width: 8rem;
}
.parent .embla2__progress--hidden {
  opacity: 0;
}
.parent .embla2__progress__bar {
  animation-name: autoplay-progress;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}
.parent .embla2__progress--hidden .embla2__progress__bar {
  animation-play-state: paused;
}
@keyframes autoplay-progress {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
