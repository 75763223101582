:root{
  --detail-medium-contrast: rgb(25, 25, 25);
  --text-high-contrast-rgb-value: 49, 49, 49;
  --detail-high-contrast: rgb(192, 192, 192);
  --text-body: rgb(222, 222, 222);

}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
body{
    background-image: url("./resources/argori_body.png");
    background-repeat: no-repeat;
    background-position: right;
    background-attachment: fixed;
}

.hide-scrollbar {
    scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}
